<template>

  <transition name="fadeView" appear mode="out-in">

  <div class="Recherche" 
  :class="whatConfig"
  :style="{height: whatHeight()}">
    <div class="Recherche--header" :class="whatConfig">
      <!-- <HeaderTitle :title="page.title"></HeaderTitle> -->
      <Header :title="page.title" :context="'search'"></Header>

      <template v-if="whatConfig == 'recherche-mobile'">
      <div class="Recherche--body--input" :class="whatConfig">
        <div class="Recherche--body--input--button-newsearch" :class="whatConfig" v-if="this.newSearch == ''" @click="setSearch()" >
          <img class="Recherche--body--input--button-newsearch--img" src="@/assets/icone/search.svg">
        </div>
        <div class="Recherche--body--input--button-reset" :class="whatConfig" v-if="this.newSearch !== ''" @click="resetSearch()" >
          <img class="Recherche--body--input--button-reset--img" src="@/assets/icone/exit.svg">
        </div>
        <input type="text" class="Recherche--body--input--search-input" :class="whatConfig" placeholder="" v-model="newSearch" @keyup.enter="setSearch()">
      </div>
      </template>

    </div>

    <template v-if="whatConfig == 'recherche-mobile'">
      <div class="Recherche--bg" :class="whatConfig"></div>
    </template>

    <div class="Recherche--body" :class="whatConfig">



      <template v-if="whatConfig != 'recherche-mobile'">
      <div class="Recherche--body--input" :class="whatConfig">
        <div class="Recherche--body--input--button-newsearch" v-if="this.newSearch == ''" @click="setSearch()" >
          <img class="Recherche--body--input--button-newsearch--img" src="@/assets/icone/search.svg">
        </div>
        <div class="Recherche--body--input--button-reset" v-if="this.newSearch !== ''" @click="resetSearch()" >
          <img class="Recherche--body--input--button-reset--img" src="@/assets/icone/exit.svg">
        </div>
        <input type="text"  class="Recherche--body--input--search-input" placeholder="" v-model="newSearch" @keyup.enter="setSearch()">
      </div>
      </template>
      
      <div class="Recherche--body--keywords" v-if="keywordDisplay" :class="whatConfig">
        <div 
          v-for="keyword in page.content" 
          :key="keyword"
          class="Recherche--body--keywords--element"
          @click="setSearch(keyword)"
          > 
        {{ keyword }} 
        </div>
      </div>

      <div class="Recherche--body--results" :class="whatConfig">
        <CardArticles :articles="articles" :widthElement="widthElement" v-if="resultsDisplay && !noResult"></CardArticles>
        <div class="Recherche--body--results--no-result" v-if="noResult">{{ noResultContent[$route.params.language] }}</div>
      </div>

    </div>
  </div>

  </transition>

</template>

<script>
// import HeaderTitle from '@/components/HeaderTitle.vue'
import CardArticles from '@/components/CardArticles.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

import Header from '@/components/Header.vue'

export default {
  name: 'Recherche',
  mixins: [clientConfigs],
  data: function(){
    return {
      state: this.$store.state,
      articles: '',

      newSearch: '',

      noResult: false,
      noResultContent: {
        fr: 'Aucun résultat',
        en: 'No result found'
      }
    }
  },

  watch: {
    newSearch: function(val){
      // this.autocomplete()

      if(val.length > 0){
        this.setSearch()
      }
    }
  },

  components: {
    CardArticles,
    Header
  },

  computed: {
    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },  // est REACTIVE si json1 existe

    // whatConfig: function(){
    //   if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
    //     return 'recherche-mobile'
    //   }else {
    //     return ''
    //   }
    // },

    whatConfig: function(){
      let whatConfigReturn = ''

      if (this.ClientFormat == 'portrait'){

        if (this.ClientWidth == 'mobileWidth' || this.ClientWidth == 'tabletWidth'){
          whatConfigReturn = 'recherche-mobile'
        }else {
          whatConfigReturn = 'recherche-mobile'
        }
      }

      return whatConfigReturn
    },
    keywordDisplay: function(){ 

      if ( !(this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait')){
        return true
      }      

      // if (this.ClientDevice === 'mobile' && this.articles == '') {
      if ((this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait') && this.newSearch == '') {  
        return true
      }else {
        return false
      }
    },
    resultsDisplay: function(){
      if (this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return !this.keywordDisplay
      }else{
        return true
      }
    },
    widthElement(){      
      let params = {
        mobile: 'two-columns',
        tablet: 'four-columns',
        desktopLandscape: 'two-columns',
        desktopPortraitMobile: 'two-columns',
        desktopPortraitNotMobile: 'four-columns',
        desktopLandscapeSmall: 'two-columns',
        desktopLandscapeMed: 'two-columns',
        desktopLandscapeBig: 'two-columns',
        desktopLandscapeBigger: 'two-columns',
        desktopLandscapeBiggest: 'two-columns',
        desktopLandscapeMax: 'two-columns'
      }

      return this.$client.getConditionnal(params)
    }
  },

  methods: {
    whatHeight(){
      // if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
       
      //   let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');

      //   let pixelTotalHeight = window.innerHeight 
      //   let pixelMargeHeight = margeArticleRaw.split('px')[0]
      //   let pixelContentHeight = window.innerHeight - pixelMargeHeight

      //   let pourentContentHeight = pixelContentHeight * 100 / pixelTotalHeight

      //   return pourentContentHeight + '%'

      // }else {
      //   return ''
      // }

      return ''

    },

    autocomplete(){
      let searchLength = this.newSearch.length
      
      this.page.autocompletion.forEach((element, index) => {
        if (this.newSearch == element.slice(0, searchLength)){
          if (!(this.newSearch == element)){
            index
            // console.log(this.page.autocompletion[index], index)
          }
        }
      })
    },
    resetSearch() {
      location.hash = ''
      this.newSearch = ''
      this.articles = ''
      this.noResult = false
    },
    hashSearch(hash){
      let hashSeach = hash.split('#')[1]
      this.setSearch(hashSeach)
    },
    setSearch(keyword) {
      this.noResult = false

      // if (keyword == null){
      //   this.articles = '';
      // }

      if (typeof keyword === 'string' && keyword != null) { 
        this.newSearch = keyword
      }
      this.setArticles()
      
    },

    async setArticles() {

      let query = { 
        Search: this.newSearch
      }

      let response = await this.$api.getArticles(query)

      if (response.content.length < 1){
        // console.log('pas de resultat')
        this.noResult = true
        this.articles = ''

      }else {
        this.maxPagination = response.max
        this.articles = response.content
      }
    },
    onResize: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        this.$forceUpdate();
      }
    }
  },
  
  created() {
    window.scroll(0, 0);
  
    if (this.$route.hash ){
      // console.log(this.$route.hash)
      this.hashSearch(this.$route.hash.replace('%20', ' '))
    }

   window.addEventListener('resize', this.onResize)
  },

  mounted(){
    this.$client.isInit = true

    // console.log(this.page)
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';


.Recherche{

  z-index: 2;

  position: absolute;
  height: 100vh;

  width: var(--page-width);
  left: calc(var(--marge-page) / 2);

  background-color: var(--fourth-color);

  overflow: hidden;
}

.Recherche--body{

  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 8% calc(100vh - 10vw - 8%);

  grid-template-rows: calc(0.5 * var(--header-title-font)) calc(100vh - var(--header-title-font) - calc(0.5 * var(--header-title-font)));

  column-gap: var(--esp-small);

  &--input{
    width: 100%;
    height: auto;

    grid-column-start: 1;
    grid-column-end: 2;

    margin-left: var(--esp-small);
  }

  &--keywords{
    width: 100%;
    font-family: 'vd-reg';
    line-height: 1.2;
    font-size: var(--search-font-keyword);
    text-align: center;

    grid-column-start: 2;
    grid-column-end: 3;

    grid-row-start: 1;
    grid-row-end: 3;
 
    overflow: scroll;
    scrollbar-width: none;



    &--element{
      margin-left: var(--esp-small);
      margin-right: var(--esp-small);

      overflow-wrap: anywhere;
      
      @supports ( hyphens: auto ) {
        hyphens: auto;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
      }
      
    }

    & div:last-child{
      margin-bottom: var(--esp-small);
    }

    & div {
      cursor: pointer;
    }
    
    // margin-top: var(--esp-small);
  }

  &--results{

    width: 100%;
    // background-color: green;

    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 2;
    grid-row-end: 3;

    overflow: scroll;
    scrollbar-width: none;

    margin-top: var(--esp-small);
    // height: calc(100vh - var(--header-title-font) - 8%);
    // 

    &--no-result{
      margin: var(--esp-small);
      // font-family: 'vd-reg';
      font-family: 'oli';
      font-size: calc(0.7 * var(--search-font-keyword));
    }
  }
}


.Recherche--body--input{
  display: flex;

  &--search-input{
    // width: calc(80% - #{$articles-marge});
    // width: 80%;

    width: calc(100% - 0.5 * var(--header-title-font));

    margin-left:var(--esp-small); 
    margin-right: calc(2 * var(--esp-small));

    border-radius: 0px;
    border: 0;
    // border-bottom: 0.5vw solid;
    outline: 0;
    color: black;
    padding: 0;
    background-color: white;
    // background-color: var(--fourth-color-lighten);

    font-family: 'vd-reg';
    font-size: calc( 0.8 * var(--search-font-input));
    padding-left: var(--esp-small);

    &::placeholder {
      color: grey;
    }
  }

  &--button-reset{

    width: calc(0.5 * var(--header-title-font));
    height: calc(0.5 * var(--header-title-font));
    // background-image: url(../assets/icone/exit.svg);
    // background-repeat: no-repeat;
    background-color: white;
    background-color: var(--fourth-color-lighten);

    // margin-left: 0.5vw;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &--img{
      height: 80%;
    }
  }

  &--button-newsearch{

    width: calc(0.5 * var(--header-title-font));
    height: calc(0.5 * var(--header-title-font));

    // background-image: url(../assets/icone/search.svg);
    // background-repeat: no-repeat;
    // margin-left: 0.5vw;
    background-color: white;
    background-color: var(--fourth-color-lighten);

    display: flex;
    flex-direction: column;
    justify-content: center;

    &--img{
      height: 80%;
    }
  }  
}


/* PORTRAIT */ // non utilisé atm

.Recherche.recherche-portrait{

  background-color: var(--fourth-color);
  z-index: 3;
  position: absolute;
  
  height: calc(100% - var(--menu-header-height));

  width: 100vw; 
  left: 0;
  top: var(--menu-header-height);

  overflow: hidden;
}


/* MOBILE */

.Recherche.recherche-mobile{

  background-color: var(--fourth-color);
  z-index: 2;
  position: absolute;
  
  // height: calc(100% - var(--menu-header-height));
  height: auto;

  width: 100vw; 
  left: 0;
  top: var(--menu-header-height);

  // overflow: hidden;
  overflow: visible;
}

.Recherche--bg.recherche-mobile{
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--fourth-color);
}

.Recherche--header.recherche-mobile{
  position: fixed;
  background-color: var(--fourth-color);
  width: 100%;
  z-index: 120;
}

.Recherche--body.recherche-mobile{
  display: block;  

  // position: absolute;

  margin-top: calc(0.6 * var(--header-title-font) + var(--header-title-font) + var(--esp-small));
  // max-height: calc(100% - (0.6 * var(--header-title-font) + var(--header-title-font) + var(--esp-small)));
  height: calc(100% - (0.6 * var(--header-title-font) + var(--header-title-font) + var(--esp-small)));
  // height: 80%;
  overflow: scroll;
  z-index: 100;
  // padding-bottom: 20vh;
}

.Recherche--body--input.recherche-mobile{
  width: calc(100% - var(--esp-small));
  z-index: 120;

}

.Recherche--body--input--search-input.recherche-mobile{
  margin-right: var(--esp-small);
  z-index: 120;
}

.Recherche--body--input--button-newsearch.recherche-mobile, .Recherche--body--input--button-reset.recherche-mobile{
  width: calc(0.6 * var(--header-title-font));
  height: calc(0.6 * var(--header-title-font));
  z-index: 120;
}



.Recherche--body--keywords.recherche-mobile{
  margin-top: var(--esp-big);
  z-index: 100;
}

.Recherche--body--results.recherche-mobile{
  margin-top: 0; 
}

.Recherche--body--input--search-input{
  background-color: white;

}


</style>